import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AspectRatio,
  Box,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Select,
  Stack,
  Switch,
  Tag,
  Text,
  useToken,
} from '@chakra-ui/react'
import useHeader from 'contexts/header/useHeader'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
} from 'recharts'

import data from '../../google-sheet/data.json'

// const getMax = (d) => d.values && Math.max(...d.values.map((x) => x.value))

const HorizonBarChart = ({ data, unit }) => {
  const [pink] = useToken('colors', ['pink.500'])

  return (
    <AspectRatio ratio={1}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          width={500}
          height={500}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis type="number" domain={[0, 100]} />
          <YAxis dataKey="city" type="category" />
          <Tooltip
            formatter={(value, name, props) => {
              return [props.payload.original, unit]
            }}
          />
          <Bar dataKey="value" fill={pink} />
          {/* <ReferenceLine x="100" stroke={gray} strokeDasharray="4 4" /> */}
        </BarChart>
      </ResponsiveContainer>
    </AspectRatio>
  )
}

const colors = [
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'blue',
  'cyan',
  'purple',
  'pink',
]

const Data = () => {
  const { setHideHeader } = useHeader()
  const colorsRef = useRef({})
  const [group, setGroup] = useState(false)
  const [ascending, setAscending] = useState(0)
  const sorted = useMemo(
    () =>
      data
        .map((d) => {
          const sorted = d.values?.sort((a, b) => b.value - a.value)
          return {
            ...d,
            category: d.category || 'N/A',
            values: sorted,
            // max: getMax(d),
            maxDiff: sorted[0].value - sorted[1].value,
          }
        })
        .sort((a, b) => {
          const groupCompare = group ? a.category?.localeCompare(b.category) : 0
          if (groupCompare !== 0) return groupCompare
          return (a.maxDiff - b.maxDiff) * (ascending ? 1 : -1)
        }),
    [ascending, group]
  )
  const getTagColor = useCallback((tag) => {
    if (!colorsRef.current[tag]) {
      colorsRef.current[tag] =
        colors[Object.keys(colorsRef.current).length % colors.length]
    }
    return colorsRef.current[tag]
  }, [])
  useEffect(() => {
    setHideHeader(true)
  }, [])
  return (
    <Container py="4" as={Stack} maxW="container.lg">
      <Heading size="md">共有 {data.length} 個指標</Heading>
      <HStack>
        <FormControl display="flex" alignItems="center" w="10em">
          <FormLabel htmlFor="grouping" mb="0">
            依分類排列？
          </FormLabel>
          <Switch
            id="grouping"
            colorScheme="pink"
            onChange={(e) => setGroup(e.target.checked)}
          />
        </FormControl>
        <Box flex="1" />
        <p>前兩名差距</p>
        <Select width="4em" onChange={(e) => setAscending(+e.target.value)}>
          <option value="0">大</option>
          <option value="1">小</option>
        </Select>
        <p>排在上</p>
      </HStack>
      <Accordion allowMultiple allowToggle>
        {sorted.map((d, i) => (
          <AccordionItem key={i}>
            <h2>
              <AccordionButton>
                <Tag colorScheme={getTagColor(d.category)}>{d.category}</Tag>
                <Box px="2" flex="1" textAlign="left">
                  {d.name}
                </Box>
                <Box pr="4">
                  diff:
                  <Text as="span" fontWeight="medium">
                    {Math.round(d.maxDiff)}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <HorizonBarChart data={d.values} unit={d.unit} />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Container>
  )
}

export default Data
